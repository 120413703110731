import {
  Anchor,
  Button,
  Container,
  Flex,
  Group,
  Paper,
  PasswordInput,
  TextInput,
  Title,
} from '@mantine/core';
import classes from './LoginPage.module.css';
import { useMutation } from 'urql';
import { LOGIN } from '@/graphql/auth.gql';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { setAccessToken, setRefreshToken } from '@/utils/tokens';
import { useAuth, useCurrentUser } from '@/authentication/AuthContext';
import { useNavigate } from 'react-router-dom';

export function LoginPage() {
  const [{ fetching }, login] = useMutation(LOGIN);
  useCurrentUser();
  const navigate = useNavigate();
  const { refreshClient } = useAuth();
  const form = useForm({
    initialValues: {
      username: '',
      password: '',
    },
  });
  return (
    <Flex w="100%" h={'100vh'} justify={'center'} align={'center'}>
      <Container w={420} mt={-100} c={'white'}>
        <Title size={'2.5rem'} ta="center" className={classes.title}>
          Welcome to{' '}
          <span
            style={{
              color: 'yellow',
            }}
          >
            12Cards
          </span>
        </Title>

        <form
          action="#"
          onSubmit={form.onSubmit(async (values) => {
            const { data } = await login({ ...values });
            const tokens = data?.login?.data;
            notifications.show({
              message: data?.login?.message,
              color: tokens ? 'green' : 'red',
            });
            if (tokens) {
              setAccessToken(tokens.accessToken);
              setRefreshToken(tokens.refreshToken);
              refreshClient();
            }
          })}
        >
          <TextInput
            label="Username"
            placeholder="rohann"
            required
            {...form.getInputProps('username')}
          />
          <PasswordInput
            label="Password"
            placeholder="Your password"
            required
            mt="md"
            {...form.getInputProps('password')}
          />
          <Button color="red" type="submit" loading={fetching} fullWidth mt="xl">
            Sign in
          </Button>
        </form>
      </Container>
    </Flex>
  );
}
