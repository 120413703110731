type SuitIconType = {
  id: 'H' | 'C' | 'D' | 'S';
  width?: number | string;
  height?: number | string;
  strokeColor?: string;
  strokeWidth?: number;
  style?: React.CSSProperties | undefined;
};
const SuitIcon = ({
  id,
  strokeColor = '#FCC419',
  strokeWidth = 10,
  width = undefined,
  height = 40,
  style,
}: SuitIconType) => {
  switch (id) {
    case 'H':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 300 375"
          xmlSpace="preserve"
          width={width}
          height={height}
          style={style}
        >
          <g>
            <path
              stroke={strokeColor}
              strokeWidth={strokeWidth}
              fill="#E03131"
              d="M217.6,26.7C180.3,26.7,150,57,150,94.3c0-37.4-30.3-67.6-67.6-67.6C45,26.7,14.7,57,14.7,94.3   c0,94.6,107.1,124.3,135.2,178.8v0.2c0,0,0-0.1,0.1-0.1c0,0,0,0.1,0.1,0.1l0-0.2c28.1-54.4,135.2-84.2,135.2-178.8   C285.3,57,255,26.7,217.6,26.7z"
            />
          </g>
        </svg>
      );
    case 'S':
      return (
        <svg
          width={width}
          height={height}
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 300 375"
          xmlSpace="preserve"
          style={style}
        >
          <path
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            d="M267.1,147.8L267.1,147.8c0.1,0,0-0.1-0.2-0.2c-4.5-4.8-9.6-8.9-15.3-12.2C226.8,114.7,178,69.7,150,15  c-28,54.7-76.8,99.7-101.6,120.5c-5.7,3.3-10.8,7.4-15.3,12.2c-0.2,0.1-0.2,0.2-0.2,0.2h0.1c-11.3,12.1-18.2,28.3-18.2,46.1  c0,37.4,30.3,67.6,67.6,67.6c26.4,0,49.3-15.1,60.4-37.2L127,285h46l-15.8-60.6c11.1,22.1,34,37.2,60.4,37.2  c37.4,0,67.6-30.3,67.6-67.6C285.3,176.1,278.4,159.9,267.1,147.8z"
          />
        </svg>
      );

    case 'D':
      return (
        <svg
          width={width}
          height={height}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 300 375"
          xmlSpace="preserve"
          style={style}
        >
          <g>
            <path
              stroke={strokeColor}
              strokeWidth={strokeWidth}
              fill="#E03131"
              d="M30.7,150c0,0,79.9-58.3,119.3-135.3C189.5,91.7,269.3,150,269.3,150S189.5,208.3,150,285.3   C110.5,208.3,30.7,150,30.7,150z"
            />
          </g>
        </svg>
      );
    case 'C':
      return (
        <svg
          width={width}
          height={height}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 300 375"
          xmlSpace="preserve"
          style={style}
        >
          <path
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            d="M217.6,126c-6,0-11.8,0.8-17.3,2.2c10.8-12,17.3-27.8,17.3-45.2c0-37.4-30.3-67.6-67.6-67.6c-37.4,0-67.6,30.3-67.6,67.6  c0,17.4,6.6,33.2,17.4,45.2c-5.6-1.5-11.4-2.3-17.4-2.3c-37.4,0-67.6,30.3-67.6,67.6c0,37.4,30.3,67.6,67.6,67.6  c26.4,0,49.3-15.1,60.4-37.2L127,284.6h46l-15.8-60.6c11.1,22.1,34,37.2,60.4,37.2c37.4,0,67.6-30.3,67.6-67.6  C285.3,156.2,255,126,217.6,126z"
          />
        </svg>
      );

    default:
      return null;
  }
};

export default SuitIcon;
