import { CURRENT_CMP_ROUND } from '@/graphql/round.gql';
import { useSubscription } from 'urql';
import SpinWheel from '../SpinWheel/SpinWheel';
const Roulette = () => {
  const [{ data }] = useSubscription({
    query: CURRENT_CMP_ROUND,
  });

  const round = data?.rounds?.[0];

  return (
    <SpinWheel
      winningCard={round?.card?.id}
      winningMultiplier={round?.multiplier?.id}
      roundId={round?.id}
    />
  );
};

export default Roulette;
