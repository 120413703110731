/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query currentUser {\n    users {\n      id\n      email\n      username\n      first_name\n      last_name\n      balance\n      auto_claim\n      sound_effects\n      print_receipts\n      tokens {\n        id\n        client\n        expires_at\n        created_at\n      }\n    }\n  }\n": types.CurrentUserDocument,
    "\n  mutation login($username: String!, $password: String!) {\n    login(username: $username, password: $password) {\n      code\n      data {\n        accessToken\n        refreshToken\n      }\n      message\n    }\n  }\n": types.LoginDocument,
    "\n  mutation authCodeExchange($code: String!, $type: String!) {\n    authCodeExchange(code: $code, type: $type) {\n      code\n      message\n      success\n      data {\n        accessToken\n        refreshToken\n      }\n    }\n  }\n": types.AuthCodeExchangeDocument,
    "\n  mutation forgotPassword($identity: String!) {\n    forgotPassword(identity: $identity) {\n      code\n      data\n      success\n      message\n    }\n  }\n": types.ForgotPasswordDocument,
    "\n  mutation forgotPasswordConfirm($input: ForgotPasswordConfirmInput!) {\n    forgotPasswordConfirm(input: $input) {\n      data {\n        accessToken\n        refreshToken\n      }\n      success\n      code\n      message\n    }\n  }\n": types.ForgotPasswordConfirmDocument,
    "\n  mutation delete_user_tokens($where: user_tokens_bool_exp!) {\n    delete_user_tokens(where: $where) {\n      returning {\n        id\n        client\n      }\n      affected_rows\n    }\n  }\n": types.Delete_User_TokensDocument,
    "\n  query prevBet {\n    bets(limit: 1, order_by: { created_at: desc_nulls_last }) {\n      id\n      total_play\n      items {\n        id\n        card_id\n        play\n      }\n      created_at\n    }\n  }\n": types.PrevBetDocument,
    "\n  mutation insertBet($object: bets_insert_input!, $on_conflict: bets_on_conflict) {\n    insert_bets_one(object: $object, on_conflict: $on_conflict) {\n      id\n      codename\n      round {\n        id\n        draw_on\n      }\n      created_at\n      items {\n        id\n        play\n        card {\n          id\n          rank_id\n          symbol_id\n        }\n      }\n    }\n  }\n": types.InsertBetDocument,
    "\n  subscription BetHistory(\n    $distinct_on: [bets_select_column!]\n    $limit: Int\n    $offset: Int\n    $order_by: [bets_order_by!]\n    $where: bets_bool_exp\n  ) {\n    bets(\n      distinct_on: $distinct_on\n      limit: $limit\n      offset: $offset\n      order_by: $order_by\n      where: $where\n    ) {\n      id\n      starting_balance\n      total_play\n      total_win\n      created_at\n      status\n      round {\n        id\n        draw_on\n        multiplier {\n          id\n          name\n          multiplier\n        }\n        card {\n          id\n          rank {\n            id\n            name\n          }\n          symbol {\n            id\n            name\n            emoji\n          }\n        }\n        updated_at\n      }\n    }\n  }\n": types.BetHistoryDocument,
    "\n  mutation claimBet($id: String!) {\n    claimBet(id: $id) {\n      code\n      message\n      data {\n        totalWin\n        receipt\n        ticketTime\n        betId\n        betCodename\n        roundDrawTime\n        roundId\n        totalPlay\n        agentId\n      }\n    }\n  }\n": types.ClaimBetDocument,
    "\n  mutation deleteBet($id: String!) {\n    deleteBet(id: $id) {\n      data {\n        id\n      }\n      code\n      message\n    }\n  }\n": types.DeleteBetDocument,
    "\n  query cards($where: cards_bool_exp, $order_by: [cards_order_by!], $limit: Int, $offset: Int) {\n    cards(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {\n      id\n      rank {\n        id\n        name\n        sequence_no\n      }\n      image_url\n      symbol {\n        id\n        emoji\n        name\n        image_url\n        sequence_no\n      }\n    }\n  }\n": types.CardsDocument,
    "\n  query symbols(\n    $where: symbols_bool_exp\n    $order_by: [symbols_order_by!]\n    $limit: Int\n    $offset: Int\n  ) {\n    symbols(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {\n      id\n      emoji\n      name\n      image_url\n    }\n  }\n": types.SymbolsDocument,
    "\n  query ranks($where: ranks_bool_exp, $order_by: [ranks_order_by!], $limit: Int, $offset: Int) {\n    ranks(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {\n      id\n      name\n      sequence_no\n    }\n  }\n": types.RanksDocument,
    "\n  query chips($where: chips_bool_exp, $order_by: [chips_order_by!], $limit: Int, $offset: Int) {\n    chips(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {\n      id\n      value\n      color\n      is_active\n    }\n  }\n": types.ChipsDocument,
    "\n  subscription roundPrevResults(\n    $where: rounds_bool_exp\n    $order_by: [rounds_order_by!]\n    $limit: Int\n    $offset: Int\n  ) {\n    rounds(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {\n      id\n      status\n      multiplier {\n        id\n        multiplier\n        name\n      }\n      card {\n        id\n        rank_id\n        symbol_id\n      }\n      draw_on\n    }\n  }\n": types.RoundPrevResultsDocument,
    "\n  subscription currentRound {\n    rounds(order_by: { draw_on: desc_nulls_last }, limit: 1, where: { status: { _eq: \"IP\" } }) {\n      id\n      draw_on\n    }\n  }\n": types.CurrentRoundDocument,
    "\n  subscription currentCompletedRound {\n    rounds(order_by: { draw_on: desc_nulls_last }, limit: 1, where: { status: { _eq: \"CP\" } }) {\n      id\n      multiplier {\n        id\n      }\n      card {\n        id\n        rank_id\n        symbol_id\n      }\n    }\n  }\n": types.CurrentCompletedRoundDocument,
    "\n  subscription currentRoundWonStats {\n    rounds(order_by: { draw_on: desc_nulls_last }, limit: 1, where: { status: { _eq: \"CP\" } }) {\n      id\n      bets_aggregate {\n        aggregate {\n          sum {\n            total_win\n          }\n        }\n      }\n    }\n  }\n": types.CurrentRoundWonStatsDocument,
    "\n  query roundQuery(\n    $where: rounds_bool_exp\n    $order_by: [rounds_order_by!]\n    $limit: Int\n    $offset: Int\n  ) {\n    rounds(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {\n      id\n      total_win\n      total_play\n      status\n      card {\n        id\n        rank {\n          id\n          name\n        }\n        symbol {\n          id\n          emoji\n          image_url\n        }\n      }\n      multiplier {\n        id\n        name\n      }\n      draw_on\n    }\n  }\n": types.RoundQueryDocument,
    "\n  query multipliers(\n    $where: multiplier_bool_exp\n    $order_by: [multiplier_order_by!]\n    $limit: Int\n    $offset: Int\n  ) {\n    multiplier(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {\n      id\n      name\n      multiplier\n    }\n  }\n": types.MultipliersDocument,
    "\n  subscription userBalance {\n    users {\n      id\n      balance\n    }\n  }\n": types.UserBalanceDocument,
    "\n  mutation updateUser($_set: users_set_input, $pk_columns: users_pk_columns_input!) {\n    update_users_by_pk(_set: $_set, pk_columns: $pk_columns) {\n      id\n      first_name\n      last_name\n      username\n      email\n      balance\n      auto_claim\n      print_receipts\n    }\n  }\n": types.UpdateUserDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query currentUser {\n    users {\n      id\n      email\n      username\n      first_name\n      last_name\n      balance\n      auto_claim\n      sound_effects\n      print_receipts\n      tokens {\n        id\n        client\n        expires_at\n        created_at\n      }\n    }\n  }\n"): (typeof documents)["\n  query currentUser {\n    users {\n      id\n      email\n      username\n      first_name\n      last_name\n      balance\n      auto_claim\n      sound_effects\n      print_receipts\n      tokens {\n        id\n        client\n        expires_at\n        created_at\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation login($username: String!, $password: String!) {\n    login(username: $username, password: $password) {\n      code\n      data {\n        accessToken\n        refreshToken\n      }\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation login($username: String!, $password: String!) {\n    login(username: $username, password: $password) {\n      code\n      data {\n        accessToken\n        refreshToken\n      }\n      message\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation authCodeExchange($code: String!, $type: String!) {\n    authCodeExchange(code: $code, type: $type) {\n      code\n      message\n      success\n      data {\n        accessToken\n        refreshToken\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation authCodeExchange($code: String!, $type: String!) {\n    authCodeExchange(code: $code, type: $type) {\n      code\n      message\n      success\n      data {\n        accessToken\n        refreshToken\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation forgotPassword($identity: String!) {\n    forgotPassword(identity: $identity) {\n      code\n      data\n      success\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation forgotPassword($identity: String!) {\n    forgotPassword(identity: $identity) {\n      code\n      data\n      success\n      message\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation forgotPasswordConfirm($input: ForgotPasswordConfirmInput!) {\n    forgotPasswordConfirm(input: $input) {\n      data {\n        accessToken\n        refreshToken\n      }\n      success\n      code\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation forgotPasswordConfirm($input: ForgotPasswordConfirmInput!) {\n    forgotPasswordConfirm(input: $input) {\n      data {\n        accessToken\n        refreshToken\n      }\n      success\n      code\n      message\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation delete_user_tokens($where: user_tokens_bool_exp!) {\n    delete_user_tokens(where: $where) {\n      returning {\n        id\n        client\n      }\n      affected_rows\n    }\n  }\n"): (typeof documents)["\n  mutation delete_user_tokens($where: user_tokens_bool_exp!) {\n    delete_user_tokens(where: $where) {\n      returning {\n        id\n        client\n      }\n      affected_rows\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query prevBet {\n    bets(limit: 1, order_by: { created_at: desc_nulls_last }) {\n      id\n      total_play\n      items {\n        id\n        card_id\n        play\n      }\n      created_at\n    }\n  }\n"): (typeof documents)["\n  query prevBet {\n    bets(limit: 1, order_by: { created_at: desc_nulls_last }) {\n      id\n      total_play\n      items {\n        id\n        card_id\n        play\n      }\n      created_at\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation insertBet($object: bets_insert_input!, $on_conflict: bets_on_conflict) {\n    insert_bets_one(object: $object, on_conflict: $on_conflict) {\n      id\n      codename\n      round {\n        id\n        draw_on\n      }\n      created_at\n      items {\n        id\n        play\n        card {\n          id\n          rank_id\n          symbol_id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation insertBet($object: bets_insert_input!, $on_conflict: bets_on_conflict) {\n    insert_bets_one(object: $object, on_conflict: $on_conflict) {\n      id\n      codename\n      round {\n        id\n        draw_on\n      }\n      created_at\n      items {\n        id\n        play\n        card {\n          id\n          rank_id\n          symbol_id\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription BetHistory(\n    $distinct_on: [bets_select_column!]\n    $limit: Int\n    $offset: Int\n    $order_by: [bets_order_by!]\n    $where: bets_bool_exp\n  ) {\n    bets(\n      distinct_on: $distinct_on\n      limit: $limit\n      offset: $offset\n      order_by: $order_by\n      where: $where\n    ) {\n      id\n      starting_balance\n      total_play\n      total_win\n      created_at\n      status\n      round {\n        id\n        draw_on\n        multiplier {\n          id\n          name\n          multiplier\n        }\n        card {\n          id\n          rank {\n            id\n            name\n          }\n          symbol {\n            id\n            name\n            emoji\n          }\n        }\n        updated_at\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription BetHistory(\n    $distinct_on: [bets_select_column!]\n    $limit: Int\n    $offset: Int\n    $order_by: [bets_order_by!]\n    $where: bets_bool_exp\n  ) {\n    bets(\n      distinct_on: $distinct_on\n      limit: $limit\n      offset: $offset\n      order_by: $order_by\n      where: $where\n    ) {\n      id\n      starting_balance\n      total_play\n      total_win\n      created_at\n      status\n      round {\n        id\n        draw_on\n        multiplier {\n          id\n          name\n          multiplier\n        }\n        card {\n          id\n          rank {\n            id\n            name\n          }\n          symbol {\n            id\n            name\n            emoji\n          }\n        }\n        updated_at\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation claimBet($id: String!) {\n    claimBet(id: $id) {\n      code\n      message\n      data {\n        totalWin\n        receipt\n        ticketTime\n        betId\n        betCodename\n        roundDrawTime\n        roundId\n        totalPlay\n        agentId\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation claimBet($id: String!) {\n    claimBet(id: $id) {\n      code\n      message\n      data {\n        totalWin\n        receipt\n        ticketTime\n        betId\n        betCodename\n        roundDrawTime\n        roundId\n        totalPlay\n        agentId\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteBet($id: String!) {\n    deleteBet(id: $id) {\n      data {\n        id\n      }\n      code\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation deleteBet($id: String!) {\n    deleteBet(id: $id) {\n      data {\n        id\n      }\n      code\n      message\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query cards($where: cards_bool_exp, $order_by: [cards_order_by!], $limit: Int, $offset: Int) {\n    cards(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {\n      id\n      rank {\n        id\n        name\n        sequence_no\n      }\n      image_url\n      symbol {\n        id\n        emoji\n        name\n        image_url\n        sequence_no\n      }\n    }\n  }\n"): (typeof documents)["\n  query cards($where: cards_bool_exp, $order_by: [cards_order_by!], $limit: Int, $offset: Int) {\n    cards(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {\n      id\n      rank {\n        id\n        name\n        sequence_no\n      }\n      image_url\n      symbol {\n        id\n        emoji\n        name\n        image_url\n        sequence_no\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query symbols(\n    $where: symbols_bool_exp\n    $order_by: [symbols_order_by!]\n    $limit: Int\n    $offset: Int\n  ) {\n    symbols(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {\n      id\n      emoji\n      name\n      image_url\n    }\n  }\n"): (typeof documents)["\n  query symbols(\n    $where: symbols_bool_exp\n    $order_by: [symbols_order_by!]\n    $limit: Int\n    $offset: Int\n  ) {\n    symbols(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {\n      id\n      emoji\n      name\n      image_url\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ranks($where: ranks_bool_exp, $order_by: [ranks_order_by!], $limit: Int, $offset: Int) {\n    ranks(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {\n      id\n      name\n      sequence_no\n    }\n  }\n"): (typeof documents)["\n  query ranks($where: ranks_bool_exp, $order_by: [ranks_order_by!], $limit: Int, $offset: Int) {\n    ranks(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {\n      id\n      name\n      sequence_no\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query chips($where: chips_bool_exp, $order_by: [chips_order_by!], $limit: Int, $offset: Int) {\n    chips(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {\n      id\n      value\n      color\n      is_active\n    }\n  }\n"): (typeof documents)["\n  query chips($where: chips_bool_exp, $order_by: [chips_order_by!], $limit: Int, $offset: Int) {\n    chips(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {\n      id\n      value\n      color\n      is_active\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription roundPrevResults(\n    $where: rounds_bool_exp\n    $order_by: [rounds_order_by!]\n    $limit: Int\n    $offset: Int\n  ) {\n    rounds(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {\n      id\n      status\n      multiplier {\n        id\n        multiplier\n        name\n      }\n      card {\n        id\n        rank_id\n        symbol_id\n      }\n      draw_on\n    }\n  }\n"): (typeof documents)["\n  subscription roundPrevResults(\n    $where: rounds_bool_exp\n    $order_by: [rounds_order_by!]\n    $limit: Int\n    $offset: Int\n  ) {\n    rounds(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {\n      id\n      status\n      multiplier {\n        id\n        multiplier\n        name\n      }\n      card {\n        id\n        rank_id\n        symbol_id\n      }\n      draw_on\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription currentRound {\n    rounds(order_by: { draw_on: desc_nulls_last }, limit: 1, where: { status: { _eq: \"IP\" } }) {\n      id\n      draw_on\n    }\n  }\n"): (typeof documents)["\n  subscription currentRound {\n    rounds(order_by: { draw_on: desc_nulls_last }, limit: 1, where: { status: { _eq: \"IP\" } }) {\n      id\n      draw_on\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription currentCompletedRound {\n    rounds(order_by: { draw_on: desc_nulls_last }, limit: 1, where: { status: { _eq: \"CP\" } }) {\n      id\n      multiplier {\n        id\n      }\n      card {\n        id\n        rank_id\n        symbol_id\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription currentCompletedRound {\n    rounds(order_by: { draw_on: desc_nulls_last }, limit: 1, where: { status: { _eq: \"CP\" } }) {\n      id\n      multiplier {\n        id\n      }\n      card {\n        id\n        rank_id\n        symbol_id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription currentRoundWonStats {\n    rounds(order_by: { draw_on: desc_nulls_last }, limit: 1, where: { status: { _eq: \"CP\" } }) {\n      id\n      bets_aggregate {\n        aggregate {\n          sum {\n            total_win\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription currentRoundWonStats {\n    rounds(order_by: { draw_on: desc_nulls_last }, limit: 1, where: { status: { _eq: \"CP\" } }) {\n      id\n      bets_aggregate {\n        aggregate {\n          sum {\n            total_win\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query roundQuery(\n    $where: rounds_bool_exp\n    $order_by: [rounds_order_by!]\n    $limit: Int\n    $offset: Int\n  ) {\n    rounds(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {\n      id\n      total_win\n      total_play\n      status\n      card {\n        id\n        rank {\n          id\n          name\n        }\n        symbol {\n          id\n          emoji\n          image_url\n        }\n      }\n      multiplier {\n        id\n        name\n      }\n      draw_on\n    }\n  }\n"): (typeof documents)["\n  query roundQuery(\n    $where: rounds_bool_exp\n    $order_by: [rounds_order_by!]\n    $limit: Int\n    $offset: Int\n  ) {\n    rounds(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {\n      id\n      total_win\n      total_play\n      status\n      card {\n        id\n        rank {\n          id\n          name\n        }\n        symbol {\n          id\n          emoji\n          image_url\n        }\n      }\n      multiplier {\n        id\n        name\n      }\n      draw_on\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query multipliers(\n    $where: multiplier_bool_exp\n    $order_by: [multiplier_order_by!]\n    $limit: Int\n    $offset: Int\n  ) {\n    multiplier(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {\n      id\n      name\n      multiplier\n    }\n  }\n"): (typeof documents)["\n  query multipliers(\n    $where: multiplier_bool_exp\n    $order_by: [multiplier_order_by!]\n    $limit: Int\n    $offset: Int\n  ) {\n    multiplier(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {\n      id\n      name\n      multiplier\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription userBalance {\n    users {\n      id\n      balance\n    }\n  }\n"): (typeof documents)["\n  subscription userBalance {\n    users {\n      id\n      balance\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateUser($_set: users_set_input, $pk_columns: users_pk_columns_input!) {\n    update_users_by_pk(_set: $_set, pk_columns: $pk_columns) {\n      id\n      first_name\n      last_name\n      username\n      email\n      balance\n      auto_claim\n      print_receipts\n    }\n  }\n"): (typeof documents)["\n  mutation updateUser($_set: users_set_input, $pk_columns: users_pk_columns_input!) {\n    update_users_by_pk(_set: $_set, pk_columns: $pk_columns) {\n      id\n      first_name\n      last_name\n      username\n      email\n      balance\n      auto_claim\n      print_receipts\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;