import useGameStore from '@/store/game.store';
import { Text } from '@mantine/core';

type GameNotificationType = {};
const GameNotification = ({}: GameNotificationType) => {
  const message = useGameStore((store) => store.message);
  return (
    <Text ta={'center'} truncate id="notification-area" c={'yellow.4'} size={'1.5rem'} fw={900}>
      {message}
    </Text>
  );
};

export default GameNotification;
