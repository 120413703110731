import { graphql } from '@/gql';

export const ROUND_PREV_RESULTS_SUBSCRIPTION = graphql(/* GraphQL */ `
  subscription roundPrevResults(
    $where: rounds_bool_exp
    $order_by: [rounds_order_by!]
    $limit: Int
    $offset: Int
  ) {
    rounds(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
      id
      status
      multiplier {
        id
        multiplier
        name
      }
      card {
        id
        rank_id
        symbol_id
      }
      draw_on
    }
  }
`);

export const CURRENT_ON_GOING_ROUND = graphql(/* GraphQL */ `
  subscription currentRound {
    rounds(order_by: { draw_on: desc_nulls_last }, limit: 1, where: { status: { _eq: "IP" } }) {
      id
      draw_on
    }
  }
`);

export const CURRENT_CMP_ROUND = graphql(/* GraphQL */ `
  subscription currentCompletedRound {
    rounds(order_by: { draw_on: desc_nulls_last }, limit: 1, where: { status: { _eq: "CP" } }) {
      id
      multiplier {
        id
      }
      card {
        id
        rank_id
        symbol_id
      }
    }
  }
`);

export const ROUND_WON_STATS = graphql(/* GraphQL */ `
  subscription currentRoundWonStats {
    rounds(order_by: { draw_on: desc_nulls_last }, limit: 1, where: { status: { _eq: "CP" } }) {
      id
      bets_aggregate {
        aggregate {
          sum {
            total_win
          }
        }
      }
    }
  }
`);

export const ROUND_QUERY = graphql(/* GraphQL */ `
  query roundQuery(
    $where: rounds_bool_exp
    $order_by: [rounds_order_by!]
    $limit: Int
    $offset: Int
  ) {
    rounds(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
      id
      total_win
      total_play
      status
      card {
        id
        rank {
          id
          name
        }
        symbol {
          id
          emoji
          image_url
        }
      }
      multiplier {
        id
        name
      }
      draw_on
    }
  }
`);

export const MULTIPLIERS = graphql(/* GraphQL */ `
  query multipliers(
    $where: multiplier_bool_exp
    $order_by: [multiplier_order_by!]
    $limit: Int
    $offset: Int
  ) {
    multiplier(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
      id
      name
      multiplier
    }
  }
`);
