import useGameStore from '@/store/game.store';
import { Box, Flex, Text } from '@mantine/core';
import Chip from '../Chip/Chip';
import SuitIcon from '../SuitIcon/SuitIcon';

type BulkGameCard = {
  symbolId?: string;
  rankId?: string;
  cardIds: number[];
  groupName?: string;
  boxStyles?: React.CSSProperties;
};
const BulkGameCard = ({ symbolId, rankId, cardIds, groupName, boxStyles }: BulkGameCard) => {
  // const valueBetted = 50;
  const selectedChipValue = useGameStore((store) => store.selectedChip);
  const addChip = useGameStore((store) => store.addChip);
  const removeChip = useGameStore((store) => store.removeChip);
  const { valueBetted, allCardsBetted } = useGameStore((store) => {
    // Initialize sum to 0
    let sum = 0;
    let totalCardsBetted = 0;

    // Loop through each cardId provided to the component
    cardIds.forEach((cardId) => {
      // Check if there is a bet for the current cardId and add its value to the sum
      if (store.bet.items[cardId]) {
        totalCardsBetted += 1;
        sum += store.bet.items[cardId].play;
      }
    });

    // Return the sum divided by 3 only is it is perfectly divisible by 3
    return {
      valueBetted: sum,
      allCardsBetted: totalCardsBetted === cardIds?.length ? true : false,
    } as const;
  });

  return (
    <Box
      mt={'md'}
      px={'xs'}
      bg={'yellow.8'}
      style={{
        cursor: selectedChipValue ? 'pointer' : 'none',
        borderRadius: 'var(--mantine-radius-md)',
        outline: '1px solid var(--mantine-color-gray-7)',
        userSelect: 'none',
        ...boxStyles,
      }}
      onClick={() => {
        if (!selectedChipValue) return;
        addChip(selectedChipValue, cardIds);
      }}
      onContextMenu={(e) => {
        e.preventDefault(); // Prevent the browser context menu from opening
        if (!selectedChipValue) return;
        removeChip(selectedChipValue, cardIds);
      }}
    >
      <Flex pos={'relative'} justify={'center'} align={'center'} gap={'xs'}>
        {symbolId ? (
          <SuitIcon height={65} id={symbolId as any} />
        ) : (
          <Flex justify={'center'} align={'center'}>
            <Text className="font-numbers" fz={'2.5rem'} h={65} fw={700}>
              {rankId}
            </Text>
          </Flex>
        )}
        <Flex
          pos={'relative'}
          justify={'center'}
          align={'center'}
          bg={'yellow.1'}
          style={{
            borderRadius: '100%',
            border: '1px solid var(--mantine-color-gray-2)',
          }}
          h={60}
          w={60}
        >
          {valueBetted && allCardsBetted ? (
            <Box
              top={'50%'}
              left={'50%'}
              pos={'absolute'}
              style={{
                transform: 'translate(-50%, -45%)',
                borderRadius: '50%',
                cursor: 'pointer',
              }}
            >
              <Chip
                isBet
                width={60}
                height={60}
                value={valueBetted}
                textProps={{
                  size: '9px',
                }}
                svgStyles={{
                  boxShadow: 'none',
                  border: '1px solid black',
                }}
              />
            </Box>
          ) : (
            'Play'
          )}
        </Flex>
        <Box
          w={'100%'}
          top={'0'}
          left={'50%'}
          pos={'absolute'}
          style={{
            borderTopRightRadius: 'var(--mantine-radius-md)',
            borderTopLeftRadius: 'var(--mantine-radius-md)',
            transform: 'translate(-50%, -90%)',
            cursor: 'pointer',
          }}
          bg={'yellow.8'}
          p={'4px'}
          fw={800}
          fz={'xs'}
          ta={'center'}
        >
          All {groupName}
        </Box>
      </Flex>
    </Box>
  );
};

export default BulkGameCard;
