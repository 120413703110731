import { USER_BALANCE } from '@/graphql/user.gql';
import useGameStore from '@/store/game.store';
import { useEffect } from 'react';
import { useSubscription } from 'urql';

function useUserBalance() {
  const [{ data }] = useSubscription({ query: USER_BALANCE });
  const [userBalance, setBalance] = useGameStore((store) => [store.userBalance, store.setBalance]);
  const balance = data?.users?.[0]?.balance || 0;

  useEffect(() => {
    if (balance !== userBalance) {
      setBalance(balance);
    }
  }, [balance]);
  return userBalance;
}

export default useUserBalance;
