import { CHIPS } from '@/graphql/game.gql';
import useGameStore from '@/store/game.store';
import { Flex } from '@mantine/core';
import { useQuery } from 'urql';
import Chip from '../Chip/Chip';
import RoundResults from '../RoundResults/RoundResults';
import RoundStats from '../RoundStats/RoundStats';
import styles from './GameChips.module.css';
import { Order_By } from '@/gql/graphql';
import { useEffect } from 'react';
type GameChipsType = {};
const GameChips = ({}: GameChipsType) => {
  const [{ fetching, data }] = useQuery({
    query: CHIPS,
    variables: { order_by: { value: Order_By.AscNullsFirst } },
  });
  const setSelectedChip = useGameStore((store) => store.setSelectedChip);
  const selectedChip = useGameStore((store) => store.selectedChip);

  useEffect(() => {
    if (!selectedChip && data?.chips?.[0]) {
      setSelectedChip(data?.chips?.[0]?.value);
    }
  }, [selectedChip, data?.chips?.[0]]);
  return (
    <div className={styles['base']}>
      <Flex direction={'column'} align={'center'} w={'100%'} px={'md'} gap={'md'}>
        <RoundResults />
        <Flex gap={'xl'}>
          <RoundStats />
          <Flex gap={'md'}>
            {fetching
              ? null
              : data?.chips?.length
                ? data.chips.map((chip) => {
                    return (
                      <Chip
                        onClick={() => setSelectedChip(chip.value)}
                        isSelected={chip.value === selectedChip}
                        id={chip.id}
                        key={chip.id}
                        value={chip.value}
                      />
                    );
                  })
                : null}
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};

export default GameChips;
