import playSoundEffect from '@/utils/playSoungEffect';
import { create } from 'zustand';

type BetItemType = {
  cardId: number;
  play: number;
};
type BetType = {
  total: number;
  items: Record<number, BetItemType>;
};

type RevealStatus = {
  status: 'reveal' | 'not-started' | 'pending';
  roundId: number | null;
};
export const ROULETTE_SPIN_TIME = 5000;
type GameState = {
  revealStatus: RevealStatus;
  message: string;
  userBalance: number;
  bet: BetType;
  selectedChip: number | null;
  setSelectedChip: (chipValue: number) => void;
  addChip: (chipValue: number, cardIds: number[]) => void;
  removeChip: (chipValue: number, cardIds: number[]) => void;
  clearBet: () => void;
  doubleBet: () => void;
  setBet: (bet: BetType) => void;
  setBalance: (balance: number) => void;
  allIn: () => void;
  setMessage: (message: string, unsetInMs?: number | null) => void;
  setRevealStatus: (status: RevealStatus) => void;
};
const useGameStore = create<GameState>((set) => ({
  revealStatus: {
    status: 'not-started',
    roundId: null,
  },
  message: '',
  userBalance: 0,
  bet: {
    total: 0,
    items: {},
  },
  selectedChip: null,
  setSelectedChip: (chipId) => set({ selectedChip: chipId }),
  setRevealStatus: (status: RevealStatus) => {
    set({ revealStatus: status });
  },
  // Function to add a chip to a card
  addChip: (chipValue, cardIds) => {
    set((state) => {
      const newItems = { ...state.bet.items };
      let newTotal = state.bet.total;

      cardIds.forEach((cardId) => {
        if (newTotal + chipValue > state.userBalance) {
          throw new Error('Adding chips exceeds user balance');
        }
        newTotal += chipValue;
        if (newItems[cardId]) {
          newItems[cardId].play += chipValue;
        } else {
          newItems[cardId] = { cardId, play: chipValue };
        }
      });

      return {
        bet: {
          total: newTotal,
          items: newItems,
        },
      };
    });
    playSoundEffect('add_chip');
  },

  // Function to remove a chip from a card
  removeChip: (chipValue, cardIds) => {
    set((state) => {
      const newItems = { ...state.bet.items };
      let newTotal = state.bet.total;

      cardIds.forEach((cardId) => {
        if (newItems[cardId]) {
          const newPlayValue = Math.max(0, newItems[cardId].play - chipValue);
          newTotal -= newItems[cardId].play - newPlayValue;
          if (newPlayValue === 0) {
            delete newItems[cardId];
          } else {
            newItems[cardId].play = newPlayValue;
          }
        }
      });

      return {
        bet: {
          total: newTotal,
          items: newItems,
        },
      };
    });
    playSoundEffect('remove_chip');
  },
  // Function to clear the current bet
  clearBet: () => {
    set({
      bet: {
        total: 0,
        items: {},
      },
    });
    playSoundEffect('remove_chip');
  },

  // Function to double the current bet
  doubleBet: () => {
    set((state) => {
      // Calculate what the new total would be if the bet was doubled
      const potentialNewTotal = state.bet.total * 2;

      // Check if doubling the bet exceeds the user's balance
      if (potentialNewTotal > state.userBalance) {
        throw new Error('Doubling bet exceeds user balance');
      }

      const newItems = {} as BetType['items'];
      Object.keys(state.bet.items).forEach((cardIdNum: any) => {
        const cardId = Number(cardIdNum);
        const item = state.bet.items[cardId];
        newItems[cardId] = { ...item, play: item.play * 2 };
      });

      return {
        bet: {
          total: potentialNewTotal,
          items: newItems,
        },
      };
    });
    playSoundEffect('chip_bulk_add');
  },

  // Function to set the bet
  setBet: (bet) => {
    set(() => {
      return {
        bet,
      };
    });
    playSoundEffect('chip_bulk_add');
  },
  setBalance: (balance) => {
    set(() => {
      return {
        userBalance: balance,
      };
    });
  },
  allIn: () => {
    set((state) => {
      const numberOfCards = Object.keys(state.bet.items).length;
      // Guard clause to handle case where there are no cards to bet on
      if (numberOfCards === 0) {
        throw new Error('No cards to bet on');
      }
      const balancePerCard = Math.floor(state.userBalance / numberOfCards);
      let remainder = state.userBalance % numberOfCards;

      const newItems: Record<number, BetItemType> = {};

      // Distribute balancePerCard to each card, and add 1 to some cards to distribute the remainder
      Object.keys(state.bet.items).forEach((cardIdStr, index) => {
        const cardId = parseInt(cardIdStr, 10);
        let playValue = balancePerCard;
        if (remainder > 0) {
          playValue += 1;
          remainder -= 1;
        }
        newItems[cardId] = { cardId, play: playValue };
      });

      return {
        bet: {
          total: state.userBalance,
          items: newItems,
        },
      };
    });
    playSoundEffect('chip_bulk_add');
  },
  setMessage(message, unsetInMs = 0) {
    set({ message });
    if (unsetInMs) {
      setTimeout(() => {
        set({ message: '' });
      }, unsetInMs);
    }
  },
}));

export default useGameStore;
