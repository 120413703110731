import useUserBalance from '@/hooks/useUserBalance';
import { Badge } from '@mantine/core';
import { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useSearchParams } from 'react-router-dom';
const UserBalance = () => {
  const [params, setParams] = useSearchParams();
  const currentBalance = useUserBalance();
  const [prevBalance, setPrevBalance] = useState<number>(currentBalance);
  useEffect(() => {
    // Only update prevBalance if currentBalance changes, and it's not the initial render
    if (prevBalance !== currentBalance) {
      setTimeout(() => {
        setPrevBalance(currentBalance);
      }, 1000);
    }
  }, [currentBalance, prevBalance]);
  return (
    <Badge
      miw={'11rem'}
      style={{
        cursor: 'pointer',
        outline: '2px solid var(--mantine-color-gray-9)',
      }}
      onClick={() => {
        params.set('modal', 'accounts');
        setParams(params);
      }}
      size="xl"
      variant="gradient"
      gradient={{ from: 'yellow', to: 'orange' }}
      fz={'lg'}
      c={'gray.9'}
      fw={900}
    >
      <CountUp
        start={prevBalance}
        end={currentBalance}
        duration={1}
        separator=","
        prefix=""
        suffix=" GP"
        useEasing={true}
      />
    </Badge>
  );
};

export default UserBalance;
