import { graphql } from '@/gql';

export const PREVIOUS_BET = graphql(/* GraphQL */ `
  query prevBet {
    bets(limit: 1, order_by: { created_at: desc_nulls_last }) {
      id
      total_play
      items {
        id
        card_id
        play
      }
      created_at
    }
  }
`);

export const INSERT_BET = graphql(/* GraphQL */ `
  mutation insertBet($object: bets_insert_input!, $on_conflict: bets_on_conflict) {
    insert_bets_one(object: $object, on_conflict: $on_conflict) {
      id
      codename
      round {
        id
        draw_on
      }
      created_at
      items {
        id
        play
        card {
          id
          rank_id
          symbol_id
        }
      }
    }
  }
`);

export const BETS_DETAILS = graphql(/* GraphQL */ `
  subscription BetHistory(
    $distinct_on: [bets_select_column!]
    $limit: Int
    $offset: Int
    $order_by: [bets_order_by!]
    $where: bets_bool_exp
  ) {
    bets(
      distinct_on: $distinct_on
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      id
      starting_balance
      total_play
      total_win
      created_at
      status
      round {
        id
        draw_on
        multiplier {
          id
          name
          multiplier
        }
        card {
          id
          rank {
            id
            name
          }
          symbol {
            id
            name
            emoji
          }
        }
        updated_at
      }
    }
  }
`);

export const CLAIM_BET = graphql(/* GraphQL */ `
  mutation claimBet($id: String!) {
    claimBet(id: $id) {
      code
      message
      data {
        totalWin
        receipt
        ticketTime
        betId
        betCodename
        roundDrawTime
        roundId
        totalPlay
        agentId
      }
    }
  }
`);

export const DELETE_BET = graphql(/* GraphQL */ `
  mutation deleteBet($id: String!) {
    deleteBet(id: $id) {
      data {
        id
      }
      code
      message
    }
  }
`);
