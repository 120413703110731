import { Order_By } from '@/gql/graphql';
import { ROUND_PREV_RESULTS_SUBSCRIPTION } from '@/graphql/round.gql';
import useGameStore from '@/store/game.store';
import { Box, Flex, Loader, Text, getGradient, useMantineTheme } from '@mantine/core';
import dayjs from 'dayjs';
import React from 'react';
import { useSubscription } from 'urql';
import SuitIcon from '../SuitIcon/SuitIcon';
type RoundResultsType = {};
const RoundResults = ({}: RoundResultsType) => {
  const theme = useMantineTheme();
  const revealStatus = useGameStore((store) => store.revealStatus);
  const [{ fetching, data }] = useSubscription({
    query: ROUND_PREV_RESULTS_SUBSCRIPTION,
    variables: {
      limit: 12,
      order_by: {
        draw_on: Order_By.Desc,
      },
    },
  });
  return (
    <Box
      style={{
        borderRadius: '10px',
        overflow: 'hidden',
        border: '6px solid #FFD1A9',
        width: '100%',
      }}
    >
      {fetching ? (
        <Flex p={'xs'} gap={'xs'} justify={'center'} align={'center'} bg={'red.6'} h={'10rem'}>
          <Loader color={'red.1'} size={70} />
        </Flex>
      ) : data?.rounds?.length ? (
        <Flex w={'100%'}>
          {data.rounds.map((round, idx) => {
            const winningCard = round.card;
            const symbolId = winningCard?.symbol_id;
            const rankId = winningCard?.rank_id;
            const multiplierName = round.multiplier?.name;

            return (
              <Flex
                gap={'2px'}
                py={'2px'}
                justify={'space-between'}
                align={'center'}
                w={idx === 1 ? '200%' : '100%'}
                style={{
                  background: round?.card
                    ? idx % 2 === 0
                      ? getGradient({ from: 'orange.8', to: 'yellow.8', deg: 180 }, theme)
                      : idx === 1
                        ? getGradient({ from: '#8B0000', to: '#000000', deg: 180 }, theme)
                        : getGradient({ from: 'brown', to: 'red.8', deg: 180 }, theme)
                    : '#c92a2a',
                }}
                direction={'column'}
                key={round.id}
              >
                {idx === 1 ? (
                  <Flex justify={'space-between'} direction={'column'}>
                    <Text
                      style={{
                        opacity: '0.8',
                      }}
                      mt={4}
                      c={'gray.1'}
                      ta={'center'}
                      size="0.7rem"
                    >
                      {round.id}
                    </Text>
                    {winningCard &&
                    revealStatus.status === 'reveal' &&
                    revealStatus.roundId === round.id ? (
                      <React.Fragment>
                        <Flex justify={'center'} align={'flex-end'}>
                          <Text
                            className="font-numbers"
                            component="span"
                            c={'white'}
                            style={{
                              textShadow: '2px 2px 4px rgba(255, 215, 0, 0.5)',
                            }}
                            fw={900}
                            fz={'5rem'}
                            mt={'-1.2rem'}
                          >
                            {rankId}
                          </Text>
                          <SuitIcon
                            style={{
                              marginBottom: 10,
                            }}
                            height={'5rem'}
                            id={symbolId as any}
                          />
                        </Flex>
                        <Text
                          mt={-30}
                          ta={'end'}
                          component="span"
                          c={'red.2'}
                          fw={900}
                          fz={'1.3rem'}
                        >
                          {multiplierName}
                        </Text>
                      </React.Fragment>
                    ) : (
                      <Loader
                        mt={12}
                        mb={4}
                        size={65}
                        color={revealStatus.status === 'pending' ? 'green.4' : 'red.4'}
                      />
                    )}
                    <Text mt={8} ta={'center'} component="span" size="xs" fw={800} c={'gray.3'}>
                      {dayjs(round.draw_on).format('h:mm A')}
                    </Text>
                  </Flex>
                ) : (
                  <React.Fragment>
                    <Text
                      style={{
                        opacity: '0.8',
                      }}
                      mt={4}
                      c={'gray.1'}
                      ta={'center'}
                      size="0.7rem"
                    >
                      {round.id}
                    </Text>
                    <Text
                      mt={-12}
                      component="span"
                      c={'gray.2'}
                      style={{
                        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                      }}
                      fw={900}
                      fz={'2.8rem'}
                      className="font-numbers"
                    >
                      {rankId}
                    </Text>
                    {winningCard && revealStatus.roundId !== round.id ? (
                      <>
                        <SuitIcon
                          style={{
                            marginTop: -12,
                          }}
                          id={symbolId as any}
                        />
                        <Text mt={-8} component="span" c={'red.2'} fw={900} fz={'1rem'}>
                          {multiplierName}
                        </Text>
                      </>
                    ) : (
                      <Loader color="red.4" />
                    )}
                    <Text component="span" size="xs" fw={600} c={winningCard ? 'gray.8' : 'red.1'}>
                      {dayjs(round.draw_on).format('h:mm A')}
                    </Text>
                  </React.Fragment>
                )}
              </Flex>
            );
          })}
        </Flex>
      ) : null}
    </Box>
  );
};

export default RoundResults;
