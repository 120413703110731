export const BetStatus = {
  CL: {
    id: 'CL',
    label: 'Claimed',
    color: 'green.8',
  },
  WN: {
    id: 'WN',
    label: 'Won',
    color: 'yellow.8',
  },
  LT: {
    id: 'LT',
    label: 'Lost',
    color: 'red.8',
  },
  PL: {
    id: 'PL',
    label: 'Placed',
    color: 'blue.8',
  },
} as const;
