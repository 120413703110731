import { useCurrentUser } from '@/authentication/AuthContext';
import { ClaimBetMutation } from '@/gql/graphql';
import { CLAIM_BET } from '@/graphql/bet.gql';
import useGameStore from '@/store/game.store';
import { returnReceiptCommand, usePrinter } from '@/utils/printer';
import { Button } from '@mantine/core';
import { useMutation } from 'urql';

type ClaimBetType = {
  betId: string;
  disabled?: boolean;
  onSuccess?: (data: ClaimBetMutation['claimBet']['data']) => void;
};
const ClaimBet = ({ betId, onSuccess, disabled }: ClaimBetType) => {
  const { data: uData } = useCurrentUser();
  const [{ fetching: cFetching }, claimBet] = useMutation(CLAIM_BET);
  const setMessage = useGameStore((store) => store.setMessage);
  const { print } = usePrinter();
  return (
    <Button
      disabled={disabled}
      onClick={async () => {
        const claimId = betId;
        if (!claimId) return;
        const { data } = await claimBet({ id: claimId.toString() });
        setMessage(data?.claimBet?.message || '', 4000);
        if (data?.claimBet?.data) {
          onSuccess?.(data?.claimBet.data);
          if (!uData?.print_receipts) return;
          const escposCommands = returnReceiptCommand({
            agent: uData?.username,
            round: data.claimBet.data.roundId,
            draw_time: data.claimBet.data.roundDrawTime,
            ticket_time: data.claimBet.data.ticketTime,
            bet_id: data.claimBet.data.betId,
            total_play: data.claimBet.data.totalPlay,
            total_win: data.claimBet.data.totalWin,
          });
          print(escposCommands);
        }
      }}
      loading={cFetching}
      color="red"
    >
      Claim
    </Button>
  );
};

export default ClaimBet;
