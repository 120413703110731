import { graphql } from '@/gql';

export const USER_BALANCE = graphql(/* GraphQL */ `
  subscription userBalance {
    users {
      id
      balance
    }
  }
`);

export const UPDATE_USER = graphql(/* GraphQL */ `
  mutation updateUser($_set: users_set_input, $pk_columns: users_pk_columns_input!) {
    update_users_by_pk(_set: $_set, pk_columns: $pk_columns) {
      id
      first_name
      last_name
      username
      email
      balance
      auto_claim
      print_receipts
    }
  }
`);
