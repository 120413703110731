import { CURRENT_ON_GOING_ROUND } from '@/graphql/round.gql';
import useGameStore from '@/store/game.store';
import { Flex, Text } from '@mantine/core';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useSubscription } from 'urql';

type RoundTimerType = {};
const RoundTimer = ({}: RoundTimerType) => {
  const message = useGameStore((store) => store.message);
  const setMessage = useGameStore((store) => store.setMessage);

  const [{ data }] = useSubscription({ query: CURRENT_ON_GOING_ROUND });
  const round = data?.rounds?.[0];
  const roundId = round?.id;
  const drawOn = round?.draw_on;
  const remainingTime = Math.round((new Date(drawOn).getTime() - new Date().getTime()) / 1000);
  return (
    <CountdownCircleTimer
      key={roundId}
      isPlaying={remainingTime > 0}
      isGrowing
      rotation="counterclockwise"
      duration={120}
      trailStrokeWidth={24}
      initialRemainingTime={remainingTime}
      colors={['#2F9E44', '#FAB005', '#E03131', '#E03131']}
      colorsTime={[120, 45, 15, 0]}
      strokeWidth={18}
      onUpdate={(remainingTime) => {
        if (remainingTime && remainingTime <= 16 && message !== 'No More Bets!') {
          setMessage('No More Bets!');
        }
      }}
      trailColor="#DEE2E6"
      isSmoothColorTransition={false}
    >
      {({ remainingTime }) => (
        <Flex className="font-numbers" justify={'center'} align={'center'} direction={'column'}>
          <Text fw={800} c={'gray.1'} size="3.9rem">
            {remainingTime > 0 ? remainingTime : 0}
          </Text>
          <Text size="xs" c={'gray.5'}>
            {roundId}
          </Text>
        </Flex>
      )}
    </CountdownCircleTimer>
  );
};

export default RoundTimer;
