import { useCurrentUser } from '@/authentication/AuthContext';
import UserAccountModal from '@/components/UserAccountModal/UserAccountModal';
import { Box } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

type DefaultLayoutType = {
  children?: React.ReactNode;
};
const DefaultLayout = ({ children }: DefaultLayoutType) => {
  const { fetching } = useCurrentUser();

  if (fetching) return null;
  return (
    <Box mih={'100vh'}>
      <Notifications autoClose={8000} position="top-center" limit={3} />
      <UserAccountModal />
      {children}
    </Box>
  );
};

export default DefaultLayout;
