import { MantineProvider } from '@mantine/core';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { LoginPage } from './components/LoginPage/LoginPage';
import NotFoundPage from './components/NotFoundPage/NotFoundPage';
import Root from './routes/root';
import './styles/index.css';
import { theme } from './theme';
import GamePage from './components/GamePage/GamePage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <NotFoundPage />,
    children: [
      {
        index: true,
        element: <LoginPage />,
      },
      {
        path: 'game',
        element: <GamePage />,
      },
    ],
  },
]);

export default function App() {
  return (
    <MantineProvider theme={theme}>
      <RouterProvider router={router} />
    </MantineProvider>
  );
}
