import { graphql } from '@/gql';

export const CURRENT_USER = graphql(/* GraphQL */ `
  query currentUser {
    users {
      id
      email
      username
      first_name
      last_name
      balance
      auto_claim
      sound_effects
      print_receipts
      tokens {
        id
        client
        expires_at
        created_at
      }
    }
  }
`);

export const LOGIN = graphql(/* GraphQL */ `
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      code
      data {
        accessToken
        refreshToken
      }
      message
    }
  }
`);

export const AUTH_CODE_EXCHANGE = graphql(/* GraphQL */ `
  mutation authCodeExchange($code: String!, $type: String!) {
    authCodeExchange(code: $code, type: $type) {
      code
      message
      success
      data {
        accessToken
        refreshToken
      }
    }
  }
`);

export const FORGOT_PASSWORD = graphql(/* GraphQL */ `
  mutation forgotPassword($identity: String!) {
    forgotPassword(identity: $identity) {
      code
      data
      success
      message
    }
  }
`);

export const FORGOT_PASSWORD_CONFIRM = graphql(/* GraphQL */ `
  mutation forgotPasswordConfirm($input: ForgotPasswordConfirmInput!) {
    forgotPasswordConfirm(input: $input) {
      data {
        accessToken
        refreshToken
      }
      success
      code
      message
    }
  }
`);

export const DELETE_USER_TOKENS = graphql(/* GraphQL */ `
  mutation delete_user_tokens($where: user_tokens_bool_exp!) {
    delete_user_tokens(where: $where) {
      returning {
        id
        client
      }
      affected_rows
    }
  }
`);
