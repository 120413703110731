import { graphql } from '@/gql';

export const CARDS = graphql(/* GraphQL */ `
  query cards($where: cards_bool_exp, $order_by: [cards_order_by!], $limit: Int, $offset: Int) {
    cards(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
      id
      rank {
        id
        name
        sequence_no
      }
      image_url
      symbol {
        id
        emoji
        name
        image_url
        sequence_no
      }
    }
  }
`);

export const SYMBOLS = graphql(/* GraphQL */ `
  query symbols(
    $where: symbols_bool_exp
    $order_by: [symbols_order_by!]
    $limit: Int
    $offset: Int
  ) {
    symbols(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
      id
      emoji
      name
      image_url
    }
  }
`);

export const RANKS = graphql(/* GraphQL */ `
  query ranks($where: ranks_bool_exp, $order_by: [ranks_order_by!], $limit: Int, $offset: Int) {
    ranks(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
      id
      name
      sequence_no
    }
  }
`);

export const CHIPS = graphql(/* GraphQL */ `
  query chips($where: chips_bool_exp, $order_by: [chips_order_by!], $limit: Int, $offset: Int) {
    chips(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
      id
      value
      color
      is_active
    }
  }
`);
