import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';

export const usePrinter = () => {
  const printerRef = useRef<USBDevice | undefined>(undefined);
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    navigator.usb.getDevices().then((devices) => {
      if (devices.length) {
        printerRef.current = devices[0];
        setIsReady(true);
      }
    });

    return function cleanup() {
      if (printerRef.current) {
        printerRef.current.close();
      }
    };
  }, []);
  async function request() {
    if (printerRef.current) return;
    try {
      const device = await navigator.usb.requestDevice({
        filters: [],
      });
      printerRef.current = device;
      setIsReady(true);
    } catch (e) {
      console.log(e, 'ERR_REQUEST');
    }
  }

  async function print(data: Uint8Array) {
    if (!printerRef.current) request();
    try {
      if (!printerRef.current?.opened) await printerRef.current?.open();
      if (!printerRef.current?.configuration) await printerRef.current?.selectConfiguration(1);
      await printerRef.current?.claimInterface(0);
      await printerRef.current?.transferOut(1, data);
    } catch (e) {
      console.log(e, 'ERR_PRINT');
    }
  }

  async function closePrinter() {
    if (!printerRef.current) return;
    try {
      await printerRef.current.close();
    } catch (e) {
      console.log(e, 'ERR_CLOSE');
    }
  }

  async function forgetPrinter() {
    if (!printerRef.current) return;
    try {
      await printerRef.current.close();
      printerRef.current = undefined;
      setIsReady(false);
    } catch (e) {
      console.log(e, 'ERR_FORGET');
    }
  }
  return {
    printer: printerRef.current,
    request,
    print,
    closePrinter,
    isReady,
    forgetPrinter,
  } as const;
};

export const PrinterCommands = {
  INIT_PRINTER: [0x1b, 0x40],
  SET_LEFT_MARGIN_ZERO: [0x1d, 0x4c, 0x00, 0x00],
  ALIGN_LEFT: [0x1b, 0x61, 0x00],
  FONT_SIZE_NORMAL: [0x1b, 0x21, 0x00],
  FONT_SIZE_DOUBLE: [0x1b, 0x21, 0x30],
  feedLines: (n: number) => [0x1b, 0x64, n],
  FULL_CUT: [0x1d, 0x56, 0x42, 0x00],
  BARCODE_CODE39: [0x1d, 0x6b, 0x04], // Adjust the type if necessary
  encodeText: (text: string) => new TextEncoder().encode(text),
} as const;

export function formatItemsForPrinting(
  items?: { card: string; play: number }[],
  columnsPerRow: number = 3
) {
  if (!items) return null;
  const rows = [];
  for (let i = 0; i < items.length; i += columnsPerRow) {
    const row = [];
    for (let j = 0; j < columnsPerRow; j++) {
      const item = items[i + j];
      row.push(item ? `${item.card}(${item.play})` : '');
    }
    // Ensure columns are aligned by padding strings within each column
    const maxLength = Math.max(...row.map((el) => el.length));
    const paddedRow = row.map((el) => el.padEnd(maxLength, ' '));
    rows.push(paddedRow.join('   ')); // Spacing between columns
  }
  return rows.join('\n');
}

export type PrintData = {
  agent?: string;
  draw_time: string;
  items?: { card: string; play: number }[];
  round: string | number;
  bet_id: string | number;
  ticket_time: string;
  total_win: number | string;
  total_play: number | string;
};
export function returnReceiptCommand(printData: PrintData) {
  const formattedItems = formatItemsForPrinting(printData.items);

  // Start by initializing the printer and setting the basic configurations
  const receiptCommands = [
    ...PrinterCommands.INIT_PRINTER,
    ...PrinterCommands.SET_LEFT_MARGIN_ZERO,
    ...PrinterCommands.ALIGN_LEFT,
    ...PrinterCommands.FONT_SIZE_DOUBLE,
    ...PrinterCommands.encodeText('## 12 Cards ##\n'),
    ...PrinterCommands.FONT_SIZE_NORMAL,
    ...PrinterCommands.feedLines(1),
  ];

  // Add the ticket details line by line for clearer modifications in future
  receiptCommands.push(
    ...PrinterCommands.encodeText(`Agent ID - ${printData.agent}\n`),
    ...PrinterCommands.encodeText(`Round ID - ${printData.round}\n`),
    ...PrinterCommands.encodeText(
      `Draw Time - ${dayjs(printData.draw_time).format('DD/MM/YY hh:mm:ss a')}\n`
    ),
    ...PrinterCommands.encodeText(
      `Ticket Time - ${dayjs(printData.ticket_time).format('DD/MM/YY hh:mm:ss a')}\n`
    ),
    ...PrinterCommands.encodeText(`Total Play - ${printData.total_play} points\n`)
  );

  if (Number(printData.total_win) > 0) {
    receiptCommands.push(
      ...PrinterCommands.encodeText(`Total Win - ${printData.total_win} points\n`)
    );
  }

  receiptCommands.push(...PrinterCommands.feedLines(2));

  // Check if total_win is more than zero
  if (Number(printData.total_win) > 0) {
    // Add congratulations message
    receiptCommands.push(
      ...PrinterCommands.FONT_SIZE_DOUBLE,
      ...PrinterCommands.encodeText('Congrats!\n'),
      ...PrinterCommands.feedLines(2)
    );
  } else if (formattedItems) {
    // Print formatted items if total_win is not more than zero
    receiptCommands.push(
      ...PrinterCommands.encodeText('Items [card] (points)\n'),
      ...PrinterCommands.encodeText(formattedItems),
      ...PrinterCommands.feedLines(2)
    );
  }

  // Add the barcode and other details
  receiptCommands.push(
    ...PrinterCommands.BARCODE_CODE39,
    ...PrinterCommands.encodeText(printData.bet_id + '\x00'), // Barcode data + NUL termination
    ...PrinterCommands.feedLines(1),
    ...PrinterCommands.FONT_SIZE_DOUBLE,
    ...PrinterCommands.encodeText(`${printData.bet_id}\n`),
    ...PrinterCommands.FONT_SIZE_NORMAL,
    ...PrinterCommands.feedLines(2),
    ...PrinterCommands.FULL_CUT
  );

  return new Uint8Array(receiptCommands);
}
