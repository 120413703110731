import { useCurrentUser } from '@/authentication/AuthContext';
import { Bets_Constraint } from '@/gql/graphql';
import { INSERT_BET, PREVIOUS_BET } from '@/graphql/bet.gql';
import useGameStore from '@/store/game.store';
import isEmpty from '@/utils/isEmpty';
import { returnReceiptCommand, usePrinter } from '@/utils/printer';
import { Button, Flex, Stack } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconPokerChip } from '@tabler/icons-react';
import { useMutation, useQuery } from 'urql';
import RoundTimer from '../RoundTimer/RoundTimer';
import styles from './GameActions.module.css';

const GameActions = () => {
  const doubleBet = useGameStore((store) => store.doubleBet);
  const clearBet = useGameStore((store) => store.clearBet);
  const setBet = useGameStore((store) => store.setBet);
  const betState = useGameStore((store) => store.bet);
  const setMessage = useGameStore((store) => store.setMessage);
  const message = useGameStore((store) => store.message);

  const [{ data, fetching }] = useQuery({ query: PREVIOUS_BET });
  const { data: uData } = useCurrentUser();
  const [{ fetching: iFetching }, insertBet] = useMutation(INSERT_BET);
  const bet = data?.bets?.[0];
  const { print } = usePrinter();
  return (
    <Flex gap={'xl'} className={styles['wheel']}>
      <Stack>
        <Button size="md" color="red" onClick={clearBet}>
          Clear
        </Button>
        <Button size="md" color="red" onClick={doubleBet}>
          Double Bet
        </Button>
        <Button
          size="md"
          color="red"
          loading={fetching}
          disabled={!fetching && !bet}
          onClick={() => {
            if (!bet) return;

            setBet({
              total: bet.total_play || 0,
              items: bet.items.reduce((prev, item) => {
                return {
                  ...prev,
                  [item.card_id]: {
                    cardId: item.card_id,
                    play: item.play,
                  },
                };
              }, {}),
            });
          }}
        >
          Repeat
        </Button>
        <Button
          disabled={message === 'No More Bets!'}
          leftSection={<IconPokerChip />}
          size="lg"
          variant="gradient"
          c="gray.9"
          gradient={{ from: 'yellow', to: 'orange ' }}
          loading={iFetching}
          onClick={async () => {
            if (isEmpty(betState.items)) return;
            const { data, error } = await insertBet({
              object: {
                items: {
                  data: Object.keys(betState.items).map((cardId) => {
                    const stateItem = betState.items[Number(cardId)];
                    return {
                      card_id: stateItem['cardId'],
                      play: stateItem['play'],
                    };
                  }),
                },
              },
              on_conflict: { constraint: Bets_Constraint.BetsPkey },
            });
            if (data?.insert_bets_one?.id) {
              setMessage(`Bet accepted #${data?.insert_bets_one.id}`);
              const totalPlay = data.insert_bets_one.items.reduce(
                (acc, item) => acc + item.play,
                0
              );
              if (uData?.print_receipts) {
                const escposCommands = returnReceiptCommand({
                  agent: uData?.username,
                  round: data.insert_bets_one?.round.id,
                  draw_time: data.insert_bets_one?.round.draw_on,
                  ticket_time: data.insert_bets_one?.created_at,
                  bet_id: data.insert_bets_one?.id,
                  total_play: totalPlay,
                  total_win: 0,
                  items: data.insert_bets_one.items.map((item) => ({
                    card: `${item.card.rank_id}${item.card.symbol_id}`,
                    play: item.play,
                  })),
                });

                print(escposCommands);
              }
              clearBet();
            } else {
              notifications.show({
                title: 'Cannot place the bet!',
                // @ts-ignore
                message: error?.graphQLErrors?.[0]?.extensions?.internal?.error?.message,
                color: 'red',
              });
            }
          }}
        >
          Bet
        </Button>
      </Stack>
      <RoundTimer />
    </Flex>
  );
};

export default GameActions;
