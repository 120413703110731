import { BetStatus } from '@/constants/bet.status';
import { Order_By } from '@/gql/graphql';
import { BETS_DETAILS } from '@/graphql/bet.gql';
import { Box, Button, Flex, Loader, Modal, Select, Table, TextInput, rem } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { IconCalendar, IconSearch } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { useSubscription } from 'urql';
import ClaimBet from '../ClaimBet/ClaimBet';
import DeleteBet from '../DeleteBet/DeleteBet';
import SuitIcon from '../SuitIcon/SuitIcon';
import useGameStore from '@/store/game.store';

type UserAccountModalType = {};
const UserAccountModal = ({}: UserAccountModalType) => {
  const revealStatus = useGameStore((store) => store.revealStatus);
  const [params, setParams] = useSearchParams();
  const form = useForm({
    initialValues: {
      search: '',
      dateFrom: dayjs().startOf('day').toDate(),
      dateTill: dayjs().endOf('day').toDate(),
      status: '',
    },
  });
  const isOpen = params.get('modal') === 'accounts';
  const [{ fetching, data }] = useSubscription({
    query: BETS_DETAILS,
    variables: {
      order_by: {
        created_at: Order_By.DescNullsLast,
      },
      where: {
        status: form.values.status
          ? {
              _eq: form.values.status,
            }
          : undefined,
        id: form.values.search
          ? {
              _eq: form.values.search.trim() ? Number(form.values.search.trim()) : undefined,
            }
          : undefined,
        created_at: {
          _gte: form.values.dateFrom.toISOString(),
          _lte: form.values.dateTill.toISOString(),
        },
      },
    },
  });
  return (
    <Modal
      size={'90%'}
      c={'gray.9'}
      title="Game History"
      opened={isOpen}
      onClose={() => {
        params.delete('modal');
        setParams(params);
      }}
    >
      <Box>
        <Flex gap={'md'} align={'flex-end'} mb="md">
          <TextInput
            label="Search"
            w={'30%'}
            placeholder="Search By Bet ID"
            leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
            {...form.getInputProps('search')}
          />
          <DateInput
            label="Date From"
            leftSection={<IconCalendar />}
            placeholder="Date From"
            {...form.getInputProps('dateFrom')}
          />
          <DateInput
            label="Date Till"
            leftSection={<IconCalendar />}
            placeholder="Date Till"
            {...form.getInputProps('dateTill')}
          />
          <Select
            label="Status"
            placeholder="Bet Status"
            data={Object.keys(BetStatus).map((key) => ({
              label: (BetStatus as any)[key]?.label,
              value: key,
            }))}
            {...form.getInputProps('status')}
          />
          <Button
            onClick={() => {
              form.reset();
            }}
          >
            Reset
          </Button>
          {/* <Tooltip label="Refresh">
            <ActionIcon onClick={() => refetch({ requestPolicy: 'network-only' })} size={'lg'}>
              <IconRefresh />
            </ActionIcon>
          </Tooltip> */}
        </Flex>
        {fetching ? (
          <Flex
            p={'xl'}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Loader size={'xl'} />
          </Flex>
        ) : data?.bets?.length ? (
          <Table.ScrollContainer minWidth={'100%'}>
            <Table verticalSpacing="xs">
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Bet ID</Table.Th>
                  <Table.Th>Round</Table.Th>
                  {/* <Table.Th>Start Balance</Table.Th> */}
                  <Table.Th>Total Play</Table.Th>
                  <Table.Th>Total Win</Table.Th>
                  {/* <Table.Th>End Balance</Table.Th> */}
                  <Table.Th>Status</Table.Th>
                  <Table.Th>Result</Table.Th>
                  <Table.Th>Draw Time</Table.Th>
                  <Table.Th>Ticket Time</Table.Th>
                  <Table.Th>Actions</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {data.bets.map((bet) => {
                  const status = BetStatus[bet.status as keyof typeof BetStatus];
                  return (
                    <Table.Tr key={bet.id}>
                      <Table.Td>{bet.id}</Table.Td>
                      <Table.Td>{bet.round.id}</Table.Td>
                      {/* <Table.Td>{bet.starting_balance.toLocaleString('en-IN')}</Table.Td> */}
                      <Table.Td>{bet.total_play.toLocaleString('en-IN')}</Table.Td>
                      <Table.Td fw={600}>{bet.total_win.toLocaleString('en-IN')}</Table.Td>
                      {/* <Table.Td>
                        {(
                          bet.starting_balance -
                          bet.total_play +
                          (bet.total_win || 0)
                        ).toLocaleString('en-IN')}
                      </Table.Td> */}
                      <Table.Td fw={800} c={status.color}>
                        {status.label}
                      </Table.Td>
                      <Table.Td fw={800}>
                        {revealStatus.roundId === bet.round.id ? (
                          bet.round.card && (
                            <Flex>
                              <Flex justify={'center'} gap={'1px'} align={'flex-end'}>
                                <SuitIcon height={20} id={bet.round.card.symbol.id as any} />
                                {bet.round.card?.rank?.id}
                              </Flex>
                              <span>-</span>
                              <span>{bet.round.multiplier?.name}</span>
                            </Flex>
                          )
                        ) : bet.round.card ? (
                          <Flex>
                            <Flex justify={'center'} gap={'1px'} align={'flex-end'}>
                              <SuitIcon height={20} id={bet.round.card.symbol.id as any} />
                              {bet.round.card?.rank?.id}
                            </Flex>
                            <span>-</span>
                            <span>{bet.round.multiplier?.name}</span>
                          </Flex>
                        ) : (
                          <Loader
                            color={revealStatus.status === 'reveal' ? 'green' : 'blue'}
                            size={'sm'}
                          />
                        )}
                      </Table.Td>
                      <Table.Td>{dayjs(bet.round.draw_on).format('Do MMM YY hh:mm:ss a')}</Table.Td>
                      <Table.Td>{dayjs(bet.created_at).format('Do MMM YY hh:mm:ss a')}</Table.Td>
                      <Table.Td>
                        <Flex gap={'xs'} justify={'center'} align={'center'}>
                          <ClaimBet betId={bet.id} disabled={status.id !== 'WN'} />
                          <DeleteBet betId={bet.id} disabled={status.id !== 'PL'} />
                        </Flex>
                      </Table.Td>
                    </Table.Tr>
                  );
                })}
              </Table.Tbody>
            </Table>
          </Table.ScrollContainer>
        ) : (
          <Box>No Bets Found</Box>
        )}
      </Box>
    </Modal>
  );
};

export default UserAccountModal;
