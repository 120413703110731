import { CARDS } from '@/graphql/game.gql';
import { clsx } from 'clsx';
import { useEffect, useMemo, useRef } from 'react';
import { Wheel } from 'spin-wheel';
import { useQuery } from 'urql';
import styles from '../Roulette/Roulette.module.css';
import { MULTIPLIERS } from '@/graphql/round.gql';
import diamond from '../../images/diamond.svg';
import useGameStore, { ROULETTE_SPIN_TIME } from '@/store/game.store';
import { Order_By } from '@/gql/graphql';

const fontFamily = 'Numbers';
const fontFamilySymbols = "'Noto Sans JP', sans-serif";
const SpinWheel = ({
  winningCard,
  winningMultiplier,
  roundId,
}: {
  winningCard: number;
  winningMultiplier: number;
  roundId: number;
}) => {
  const setRevealStatus = useGameStore((store) => store.setRevealStatus);
  const wheelRef = useRef<any>(null);
  const wheelRefInner = useRef<any>(null);
  const wheelRefMultiplier = useRef<any>(null);
  const [{ data }] = useQuery({
    query: CARDS,
    variables: {
      order_by: {},
    },
  });

  const [{ data: mData }] = useQuery({
    query: MULTIPLIERS,
    variables: {
      order_by: {
        multiplier: Order_By.Asc,
      },
    },
  });
  const multipliers = mData?.multiplier || [];
  const cards = data?.cards || [];
  const rankSortedCards = useMemo(() => {
    return structuredClone(cards).sort((a, b) => {
      if (!a.rank || !b.rank) return 0;
      return a.rank.id > b.rank.id ? 1 : -1;
    });
  }, [cards]);

  useEffect(() => {
    const container = document.getElementById('roulette_outer');
    if (container && cards.length) {
      const wheel = new Wheel(container, {
        isInteractive: false,
        itemLabelRadius: 0.76,
        itemLabelAlign: 'center',
        itemLabelFontSizeMax: 70,
        itemLabelFont: fontFamily,
        itemLabelBaselineOffset: 0.1,
        lineWidth: 1,
        radius: 1,
        lineColor: '#000',
        rotationSpeedMax: 700,
        rotationResistance: -70,
        itemLabelRotation: 90,
        itemLabelStrokeWidth: 2,
        itemLabelStrokeColor: '#fbbf24',
        items: cards.map((card, idx) => ({
          label: `${card.rank?.id}`,
          labelColor: 'white',
          backgroundColor: idx % 2 === 0 ? '#b91c1c' : '#450a0a',
        })),
      });
      wheelRef.current = wheel;
    }
  }, [JSON.stringify(cards)]);

  useEffect(() => {
    const containerInner = document.getElementById('roulette_inner');
    if (containerInner && rankSortedCards.length) {
      const wheelInner = new Wheel(containerInner, {
        isInteractive: false,
        itemLabelRadius: 0.7,
        itemLabelAlign: 'center',
        itemLabelFontSizeMax: 40,
        itemLabelFont: fontFamilySymbols,
        radius: 0.6,
        lineWidth: 1,
        lineColor: '#000',
        rotationSpeedMax: 700,
        rotationResistance: -70,
        itemLabelRotation: 90,
        itemLabelStrokeWidth: 1,
        itemLabelStrokeColor: '#E67700',
        items: rankSortedCards.map((card, idx) => ({
          label: `${card.symbol?.emoji}`,
          labelColor: ['H', 'D'].includes(card.symbol?.id) ? 'red' : 'black',
          backgroundColor: idx % 2 === 0 ? '#fbbf24' : '#fb923c',
        })),
      });
      wheelRefInner.current = wheelInner;
    }
  }, [JSON.stringify(rankSortedCards)]);

  useEffect(() => {
    const containerMultiplier = document.getElementById('roulette_multiplier');
    if (containerMultiplier && multipliers.length) {
      const wheelMultiplier = new Wheel(containerMultiplier, {
        isInteractive: false,
        itemLabelRadius: 0.7,
        itemLabelAlign: 'center',
        itemLabelFontSizeMax: 14,
        radius: 0.3,
        lineWidth: 1,
        lineColor: '#000',
        rotationSpeedMax: 700,
        rotationResistance: -70,
        itemLabelRotation: 90,
        itemLabelStrokeWidth: 1,
        itemLabelStrokeColor: '#E67700',
        items: multipliers.map((multiplier) => ({
          label: `${multiplier.name}`,
          labelColor: 'white',
          backgroundColor:
            multiplier.multiplier >= 7
              ? '#c2410c'
              : multiplier.multiplier >= 3
                ? '#f97316'
                : '#fb923c',
        })),
      });
      wheelRefMultiplier.current = wheelMultiplier;
    }
  }, [JSON.stringify(multipliers)]);
  useEffect(() => {
    if (
      wheelRef.current &&
      wheelRefInner.current &&
      wheelRefMultiplier.current &&
      winningCard &&
      winningMultiplier
    ) {
      const winningItemIdx = cards.findIndex((card) => card.id === winningCard);
      const winningSymbolIdx = rankSortedCards.findIndex((card) => card.id === winningCard);
      const winningMultiplierIdx = multipliers.findIndex(
        (multiplier) => multiplier.id === winningMultiplier
      );

      setRevealStatus({ status: 'pending', roundId });
      setTimeout(() => {
        setRevealStatus({ status: 'reveal', roundId });
      }, ROULETTE_SPIN_TIME);

      wheelRef.current.spinToItem(winningItemIdx, ROULETTE_SPIN_TIME, true, 6, 1, null);
      wheelRefInner.current.spinToItem(winningSymbolIdx, ROULETTE_SPIN_TIME, true, 6, -1, null);
      wheelRefMultiplier.current.spinToItem(
        winningMultiplierIdx,
        ROULETTE_SPIN_TIME,
        true,
        6,
        1,
        null
      );
    }
  }, [winningCard, winningMultiplier, setRevealStatus, roundId]);
  return (
    <div className={styles['wheel']}>
      <div id="roulette_outer" className={clsx(styles['canvas'], styles['outer'])} />
      <div id="roulette_inner" className={clsx(styles['canvas'], styles['inner'])} />
      <div id="roulette_multiplier" className={clsx(styles['canvas'], styles['multiplier'])} />
      <img height={90} src={diamond} alt="diamond" className={clsx(styles['pointer'])} />
    </div>
  );
};

export default SpinWheel;
