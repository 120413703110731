import { Order_By } from '@/gql/graphql';
import { CARDS } from '@/graphql/game.gql';
import { Box, Flex, SimpleGrid } from '@mantine/core';
import { useQuery } from 'urql';
import BulkGameCard from '../BulkGameCard/BulkGameCard';
import GameCard from '../GameCard/GameCard';
import styles from './GameCards.module.css';
type GameCardsType = {};
const GameCards = ({}: GameCardsType) => {
  const [{ data, fetching }] = useQuery({
    query: CARDS,
    variables: {
      order_by: [
        {
          rank: {
            sequence_no: Order_By.Asc,
          },
        },
        {
          symbol: {
            sequence_no: Order_By.Asc,
          },
        },
      ],
    },
  });
  const cardsBySymbol: Record<string, number[]> = {};
  const cardsByRank: Record<string, number[]> = {};

  const gameCards = [];
  for (const card of data?.cards || []) {
    const symbolId = card.symbol.id;
    const rankId = card.rank?.id;

    if (!cardsBySymbol[symbolId]) {
      cardsBySymbol[symbolId] = [card.id];
    } else {
      cardsBySymbol[symbolId].push(card.id);
    }
    if (!rankId) {
      continue;
    } else if (!cardsByRank[rankId]) {
      cardsByRank[rankId] = [card.id];
    } else {
      cardsByRank[rankId].push(card.id);
    }

    gameCards.push(<GameCard key={card.id} card={card as any} />);
  }

  return (
    <div className={styles['base']}>
      {fetching ? (
        'Loading...'
      ) : data?.cards?.length ? (
        <Box>
          <Flex>
            <Box mt={100} mr={'lg'}>
              <Box py={'md'} mt={'md'}>
                <BulkGameCard rankId="J" cardIds={cardsByRank['J']} groupName={'Jacks'} />
              </Box>
              <Box py={'md'} mt={'md'}>
                <BulkGameCard rankId="Q" cardIds={cardsByRank['Q']} groupName={'Queens'} />
              </Box>
              <Box py={'md'} mt={'md'}>
                <BulkGameCard rankId="K" cardIds={cardsByRank['K']} groupName={'Kings'} />
              </Box>
            </Box>
            <SimpleGrid cols={4} verticalSpacing={'sm'} spacing={'2rem'}>
              <BulkGameCard symbolId="H" cardIds={cardsBySymbol['H']} groupName={'Hearts'} />
              <BulkGameCard symbolId="S" cardIds={cardsBySymbol['S']} groupName={'Spades'} />
              <BulkGameCard symbolId="D" cardIds={cardsBySymbol['D']} groupName={'Diamonds'} />
              <BulkGameCard symbolId="C" cardIds={cardsBySymbol['C']} groupName={'Clubs'} />
              {gameCards}
            </SimpleGrid>
          </Flex>
        </Box>
      ) : (
        'No Results'
      )}
    </div>
  );
};

export default GameCards;
