import { Cards } from '@/gql/graphql';
import useGameStore from '@/store/game.store';
import { Box } from '@mantine/core';
import Chip from '../Chip/Chip';
import ribbon from '../../images/ribbon.png';
type GameCardType = {
  card: Pick<Cards, 'symbol' | 'id' | 'rank' | 'rank_id'>;
};
const GameCard = ({ card }: GameCardType) => {
  const valueBetted = useGameStore((store) => store.bet.items?.[card.id]?.play);
  const selectedChipValue = useGameStore((store) => store.selectedChip);
  const addChip = useGameStore((store) => store.addChip);
  const removeChip = useGameStore((store) => store.removeChip);

  return (
    <Box
      style={{
        userSelect: 'none',
      }}
      pos={'relative'}
    >
      <img
        style={{
          top: -20,
          left: '50%',
          right: '50%',
          position: 'absolute',
          height: 'auto',
          width: 180,
          transform: 'translateX(-50%)',
        }}
        src={ribbon}
      />
      <Box
        style={{
          cursor: selectedChipValue ? 'pointer' : 'none',
          display: 'flex',
          justifyContent: 'center',
        }}
        onClick={() => {
          if (!selectedChipValue) return;
          addChip(selectedChipValue, [card.id]);
        }}
        onContextMenu={(e) => {
          e.preventDefault(); // Prevent the browser context menu from opening
          if (!selectedChipValue) return;
          removeChip(selectedChipValue, [card.id]);
        }}
        pos={'relative'}
      >
        <img
          width={80}
          src={`https://${import.meta.env.VITE_API_DOMAIN}/cdn/${card.symbol.id}${card.rank
            ?.id}.svg`}
        />
        {valueBetted ? (
          <Box
            top={'50%'}
            left={'50%'}
            pos={'absolute'}
            style={{
              transform: 'translate(-50%, -50%)',
              borderRadius: '50%',
              cursor: 'pointer',
            }}
          >
            <Chip
              isBet
              width={70}
              height={70}
              value={valueBetted}
              textProps={{
                size: '9px',
              }}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default GameCard;
