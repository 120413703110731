type GameHeaderType = {};
import { useAuth, useCurrentUser } from '@/authentication/AuthContext';
import { DELETE_USER_TOKENS } from '@/graphql/auth.gql';
import { UPDATE_USER } from '@/graphql/user.gql';
import { Button, Checkbox, Flex, Input, Text } from '@mantine/core';
import { IconCheck, IconLogout } from '@tabler/icons-react';
import { useState } from 'react';
import { useMutation } from 'urql';
import ClaimBet from '../ClaimBet/ClaimBet';
import GameNotification from '../GameNotification/GameNotification';
import UserBalance from '../UserBalance/UserBalance';
import styles from './GameHeader.module.css';
import { removeAccessToken, removeRefreshToken } from '@/utils/tokens';
import { usePrinter } from '@/utils/printer';
const TOGGLES = [
  {
    id: 'auto_claim',
    label: 'AC',
  },
  {
    id: 'sound_effects',
    label: 'Sound',
  },
  {
    id: 'print_receipts',
    label: 'Print Receipts',
  },
];
const GameHeader = ({}: GameHeaderType) => {
  const { refreshClient } = useAuth();
  const { data } = useCurrentUser();
  const [{ fetching }, deleteTokens] = useMutation(DELETE_USER_TOKENS);
  const [{ fetching: uFetching }, updateUser] = useMutation(UPDATE_USER);
  const [betId, setId] = useState('');
  const { isReady, request, forgetPrinter } = usePrinter();
  return (
    <header className={styles['base']}>
      <Flex justify={'space-between'} align={'center'}>
        <Flex align={'center'} justify={'center'} gap={'sm'}>
          <Flex justify={'center'} align={'center'}>
            <Input
              autoFocus
              value={betId}
              onChange={(e) => {
                setId(e.target.value);
              }}
              id="bet_id_claim"
              placeholder="Bet ID"
            />
            <ClaimBet
              betId={betId.trim()}
              onSuccess={() => {
                setId('');
              }}
            />
          </Flex>
          <Flex gap={'xs'} align={'center'}>
            {TOGGLES.map((toggle) => {
              const value = data?.[toggle.id as keyof typeof data] as boolean;
              const newValue = !value;
              const key = toggle.id;
              return (
                <Checkbox
                  key={toggle.id}
                  id={toggle.id}
                  disabled={uFetching}
                  onChange={() => {
                    updateUser({
                      pk_columns: {
                        id: data?.id,
                      },
                      _set: {
                        [key]: newValue,
                      },
                    });
                    if (key === 'sound_effects') {
                      localStorage.setItem('sound_effects', newValue.toString());
                    }
                  }}
                  checked={value}
                  color="red"
                  c="white"
                  label={toggle.label}
                />
              );
            })}
            <Button
              disabled={!data?.print_receipts}
              color={isReady ? 'green' : 'blue'}
              leftSection={isReady ? <IconCheck /> : null}
              radius={'lg'}
              size="xs"
              onClick={() => {
                if (isReady) {
                  forgetPrinter();
                } else {
                  request();
                }
              }}
            >
              {isReady ? 'Connected' : 'Connect'}
            </Button>
          </Flex>
        </Flex>

        <GameNotification />
        <Flex>
          <Flex gap={'xs'} align={'center'}>
            <Text c={'white'} fw={500} size="xl">
              Welcome {data?.username}
            </Text>
            <UserBalance />
          </Flex>
          <Button
            loading={fetching}
            onClick={async () => {
              await deleteTokens({ where: {} });
              removeAccessToken();
              removeRefreshToken();
              refreshClient();
            }}
            leftSection={<IconLogout />}
            ml={'xs'}
            variant="subtle"
            color="red"
          >
            Logout
          </Button>
        </Flex>
      </Flex>
    </header>
  );
};

export default GameHeader;
