import { DeleteBetMutation } from '@/gql/graphql';
import { DELETE_BET } from '@/graphql/bet.gql';
import useGameStore from '@/store/game.store';
import { ActionIcon, Tooltip } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { useMutation } from 'urql';

type DeleteBetType = {
  betId: string;
  disabled?: boolean;
  onSuccess?: (data: DeleteBetMutation['deleteBet']['data']) => void;
};
const DeleteBet = ({ betId, onSuccess, disabled }: DeleteBetType) => {
  const [{ fetching: cFetching }, deleteBet] = useMutation(DELETE_BET);
  const setMessage = useGameStore((store) => store.setMessage);
  return (
    <Tooltip label="Delete Bet">
      <ActionIcon
        size={'lg'}
        disabled={disabled}
        onClick={async () => {
          if (!betId) return;
          const { data } = await deleteBet({ id: betId });
          setMessage(data?.deleteBet?.message || '', 4000);
          onSuccess?.(data?.deleteBet.data);
        }}
        loading={cFetching}
        color="red.4"
      >
        <IconTrash />
      </ActionIcon>
    </Tooltip>
  );
};

export default DeleteBet;
