import { ROUND_WON_STATS } from '@/graphql/round.gql';
import useGameStore from '@/store/game.store';
import { Box, Flex, Text } from '@mantine/core';
import confetti from 'canvas-confetti';
import { useEffect, useState } from 'react';
import { useSubscription } from 'urql';

type RoundStatsType = {};
const RoundStats = ({}: RoundStatsType) => {
  const [{ data }] = useSubscription({
    query: ROUND_WON_STATS,
  });
  const round = data?.rounds?.[0];
  const revealStatus = useGameStore((store) => store.revealStatus);
  const betsAgg = round?.bets_aggregate?.aggregate;
  const totalWin = betsAgg?.sum?.total_win || 0;

  const [displayTotalWin, setDisplayTotalWin] = useState(0);
  const totalPlay = useGameStore((store) => store.bet.total);
  const setMessage = useGameStore((store) => store.setMessage);

  useEffect(() => {
    if (revealStatus.status === 'reveal' && revealStatus.roundId === round?.id) {
      if (totalWin > 0) {
        var duration = 12 * 1000;
        setMessage(`You have won ${totalWin} GP!`, 4000);
        setDisplayTotalWin(totalWin);
        setTimeout(() => {
          setDisplayTotalWin(0);
        }, duration);
        var end = Date.now() + 3 * 1000;
        var colors = ['#bb0000', '#ffffff'];

        (function frame() {
          confetti({
            particleCount: 2,
            angle: 60,
            spread: 55,
            origin: { x: 0 },
            colors: colors,
          });
          confetti({
            particleCount: 2,
            angle: 120,
            spread: 55,
            origin: { x: 1 },
            colors: colors,
          });

          if (Date.now() < end) {
            requestAnimationFrame(frame);
          }
        })();

        // if (round.multiplier?.multiplier >= 5) {
        var animationEnd = Date.now() + duration;
        var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

        function randomInRange(min: number, max: number) {
          return Math.random() * (max - min) + min;
        }

        var interval: NodeJS.Timeout = setInterval(function () {
          var timeLeft = animationEnd - Date.now();

          if (timeLeft <= 0) {
            return clearInterval(interval);
          }

          var particleCount = 50 * (timeLeft / duration);
          // since particles fall down, start a bit higher than random
          confetti({
            ...defaults,
            particleCount,
            origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
          });
          confetti({
            ...defaults,
            particleCount,
            origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
          });
        }, 250);
        // }
      } else if (totalWin === 0) {
        setMessage(`Better Luck Next Time!`, 4000);
      }
    }
  }, [totalWin, revealStatus.roundId, revealStatus.status, round?.id]);
  return (
    <Flex
      miw={'14rem'}
      maw={'20rem'}
      direction={'column'}
      h={'fit-content'}
      fw={700}
      c={'gray.8'}
      gap={'md'}
    >
      <Flex
        style={{
          borderRadius: '10px',
        }}
        bg={'yellow.5'}
        p={'xs'}
        justify={'space-between'}
      >
        <Box fw={600} component="span">
          Play:{' '}
        </Box>
        <Text fw={700}>{totalPlay} GP</Text>
      </Flex>
      <Flex
        style={{
          borderRadius: '10px',
        }}
        bg={'yellow.5'}
        p={'xs'}
        justify={'space-between'}
      >
        <Box fw={600} component="span">
          Win:{' '}
        </Box>
        <Text fw={700}>{displayTotalWin} GP</Text>
      </Flex>
    </Flex>
  );
};

export default RoundStats;
