import { useCurrentUser } from '@/authentication/AuthContext';
import { Flex } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import GameActions from '../GameActions/GameActions';
import GameCards from '../GameCards/GameCards';
import GameChips from '../GameChips/GameChips';
import GameHeader from '../GameHeader/GameHeader';
import Roulette from '../Roulette/Roulette';
const GamePage = () => {
  const { data } = useCurrentUser();
  const navigate = useNavigate();
  if (!data?.id) {
    navigate('/');
    return;
  }
  return (
    <div>
      <GameHeader />
      <Flex direction="column" p={'md'}>
        <Flex gap={'md'}>
          <GameCards />
          <Roulette />
        </Flex>
        <Flex gap={'md'}>
          <GameChips />
          <GameActions />
        </Flex>
      </Flex>
    </div>
  );
};

export default GamePage;
